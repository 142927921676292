<template>
  <div
    v-if="this.visible"
    id="crm-relationship__tree-modal"
    class="crm-relationship-mapping-tree"
    hide-footer
    centered
    :no-close-on-backdrop="true"
    size="sm"
    @close="close"
  >
    <div class="tw-flex tw-items-center modal__body" style="border-bottom: solid 1px gainsboro;padding-bottom: 12px;">
      <feather-icon
        icon="GitPullRequestIcon"
        class="mr-50"
      />
      <p class="tw-inline-block m-0 line-clamp-1 tw-text-base tw-font-medium">{{ this.$t('Company Relationship Tree' ) }}</p>
    </div>
    <br />
    <div style="display: flex; align-items: center; justify-content: center;">
      <b-spinner v-if="loading" variant="primary" />
    </div>
    <div v-if="!loading">
      <div id="corporateStructure" class="corporate-structure corporate-structure-root">
        <div :class="{'company': true, 'active': requestingNode == this.tree.uuid, }">
          <div class="company-name-root">
            <prozess-resource-name
              :id="`company-name-root-link`" :title="this.tree.name"
              :class="{'text-nowrap d-inline-block': true, 'requesting-node-name': this.requestingNode == this.tree.uuid,}"
              :has-permission="$can('Read', 'CRM')"
              :on-click="close"
              :to="{ name: `${this.tree.schema}-view`,
                     params: { id: this.tree.uuid, tab: GenericTabs.OVERVIEW_360,}, }"
            />
            <b-button :id="`popover-target-root-00000`" variant="link">
              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="18" height="18" viewBox="0 0 24 24" style="fill:#40C057; margin-top: -3px;">
                <path d="M12,2C6.477,2,2,6.477,2,12s4.477,10,10,10s10-4.477,10-10S17.523,2,12,2z M12,17L12,17c-0.552,0-1-0.448-1-1v-4 c0-0.552,0.448-1,1-1h0c0.552,0,1,0.448,1,1v4C13,16.552,12.552,17,12,17z M12.5,9h-1C11.224,9,11,8.776,11,8.5v-1 C11,7.224,11.224,7,11.5,7h1C12.776,7,13,7.224,13,7.5v1C13,8.776,12.776,9,12.5,9z"></path>
              </svg>
            </b-button>
            <b-popover :target="`popover-target-root-00000`" triggers="click" placement="top">
              <template #title>{{ tree.name }}</template>
              <b>{{ this.tree.name }}</b>
              <div v-for="(infoTxt, ii) in tree.info" :key="ii">
                <p style="margin:0px">
                  <span style="font-weight: 500;">{{ infoTxt.name + ": " }}</span>
                  <span>{{ infoTxt.value }}</span>
                </p>
              </div>
            </b-popover>
          </div>
          <div class="sub-info">
            <div v-for="(infoTxt, ii) in this.tree.info" :key="ii">
              <p v-if="ii<3">
                <span style="font-weight: 500;">{{ infoTxt.name + ": " }}</span>
                <span>{{ infoTxt.value }}</span>
              </p>
            </div>
          </div>
        </div>
        <ul v-if="Object.values(this.tree.child).length>0" class="child">
          <li v-for="(company, name, index) in this.tree.child" :key="name" class="item">
            <div v-if="index!=Object.values(tree.child).length-1&&Object.values(company.child).length>0" :style="{&quot;borderLeft&quot;:&quot;3px dotted #CCCCCC&quot;, &quot;display&quot;:&quot;block&quot;, &quot;width&quot;:&quot;0&quot;, &quot;position&quot;:&quot;absolute&quot;, &quot;left&quot;:&quot;-1.25em&quot;, &quot;top&quot;:&quot;7px&quot;, &quot;height&quot;:&quot;100%&quot;, &quot;padding&quot;:&quot;0px&quot;, &quot;content&quot;:&quot;&quot;}"></div>
            <div :class="{'company': true, 'active': requestingNode == name, }">
              <div class="company-name">
                <prozess-resource-name
                  :id="`company-name-${name}-link`" :title="company.name"
                  :class="{'text-nowrap d-inline-block': true, 'requesting-node-name': requestingNode == name, }"
                  :has-permission="$can('Read', 'CRM')"
                  :on-click="close"
                  :to="{ name: `${company.schema}-view`, params: { id: name, tab: GenericTabs.OVERVIEW_360, }, }"
                />
                <b-button :id="`popover-target-${index}`" variant="link">
                  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="18" height="18" viewBox="0 0 24 24" style="fill:#40C057;margin-top: -3px;"> <path d="M12,2C6.477,2,2,6.477,2,12s4.477,10,10,10s10-4.477,10-10S17.523,2,12,2z M12,17L12,17c-0.552,0-1-0.448-1-1v-4 c0-0.552,0.448-1,1-1h0c0.552,0,1,0.448,1,1v4C13,16.552,12.552,17,12,17z M12.5,9h-1C11.224,9,11,8.776,11,8.5v-1 C11,7.224,11.224,7,11.5,7h1C12.776,7,13,7.224,13,7.5v1C13,8.776,12.776,9,12.5,9z"></path></svg>
                </b-button>
                <b-popover :target="`popover-target-${index}`" triggers="click" placement="top">
                  <template #title>{{ company.name }}</template>
                  <b>{{ company.relationName }}</b> til {{ company.parentName }}
                  <div v-for="(infoTxt, ii) in company.info" :key="ii">
                    <p style="margin:0px">
                      <span style="font-weight: 500;">{{ infoTxt.name + ": " }}</span>
                      <span>{{ infoTxt.value }}</span>
                    </p>
                  </div>
                </b-popover>
              </div>
              <div class="sub-info">
                <div v-for="(infoTxt, ii) in company.info" :key="ii">
                  <p v-if="ii<3">
                    <span style="font-weight: 500;">{{ infoTxt.name + ": " }}</span>
                    <span>{{ infoTxt.value }}</span>
                  </p>
                </div>
              </div>
            </div>
            <ul v-if="Object.values(company.child).length>0" class="child">
              <li v-for="(childCompany, childName, i) in company.child" :key="i" class="item">
                <div v-if="i!=Object.values(company.child).length-1&&Object.values(childCompany.child).length>0" :style="{&quot;borderLeft&quot;:&quot;3px dotted #CCCCCC&quot;, &quot;display&quot;:&quot;block&quot;, &quot;width&quot;:&quot;0&quot;, &quot;position&quot;:&quot;absolute&quot;, &quot;left&quot;:&quot;-1.25em&quot;, &quot;top&quot;:&quot;7px&quot;, &quot;height&quot;:&quot;100%&quot;, &quot;padding&quot;:&quot;0px&quot;, &quot;content&quot;:&quot;&quot;}"></div>
                <div class="company-wrapper">
                  <div :class="{'company': true, 'active': requestingNode == childName, }">
                    <div class="company-name">
                      <prozess-resource-name
                        :id="`company-name-${childName}-link`"
                        :title="childCompany.name"
                        :class="{
                          'text-nowrap d-inline-block': true,
                          'requesting-node-name': requestingNode == childName,
                        }"
                        :has-permission="$can('Read', 'CRM')"
                        :on-click="close"
                        :to="{
                          name: `${childCompany.schema}-view`,
                          params: {
                            id: childName,
                            tab: GenericTabs.OVERVIEW_360,
                          },
                        }"
                      />
                      <b-button :id="`popover-target-2-${childCompany.uuid}`" variant="link">
                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="18" height="18" viewBox="0 0 24 24" style="fill:#40C057; margin-top: -3px;">
                          <path d="M12,2C6.477,2,2,6.477,2,12s4.477,10,10,10s10-4.477,10-10S17.523,2,12,2z M12,17L12,17c-0.552,0-1-0.448-1-1v-4 c0-0.552,0.448-1,1-1h0c0.552,0,1,0.448,1,1v4C13,16.552,12.552,17,12,17z M12.5,9h-1C11.224,9,11,8.776,11,8.5v-1 C11,7.224,11.224,7,11.5,7h1C12.776,7,13,7.224,13,7.5v1C13,8.776,12.776,9,12.5,9z"></path>
                        </svg>
                      </b-button>
                      <b-popover :target="`popover-target-2-${childCompany.uuid}`" triggers="click" placement="top">
                        <template #title>{{ childCompany.name }}</template>
                        <b>{{ childCompany.relationName }}</b> til {{ childCompany.parentName }}
                        <div v-for="(infoTxt, ii) in childCompany.info" :key="ii">
                          <p style="margin:0px">
                            <span style="font-weight: 500;">{{ infoTxt.name + ": " }}</span>
                            <span>{{ infoTxt.value }}</span>
                          </p>
                        </div>
                      </b-popover>
                    </div>
                    <div class="sub-info">
                      <div v-for="(infoTxt, ii) in childCompany.info" :key="ii">
                        <p v-if="ii<3">
                          <span style="font-weight: 500;">{{ infoTxt.name + ": " }}</span>
                          <span>{{ infoTxt.value }}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <ul v-if="Object.values(childCompany.child).length>0" class="child">
                    <li v-for="(company3, name3, index3) in childCompany.child" :key="name3" class="item">
                      <div v-if="index3!=Object.values(childCompany.child).length-1&&Object.values(company3.child).length>0" :style="{&quot;borderLeft&quot;:&quot;3px dotted #CCCCCC&quot;, &quot;display&quot;:&quot;block&quot;, &quot;width&quot;:&quot;0&quot;, &quot;position&quot;:&quot;absolute&quot;, &quot;left&quot;:&quot;-1.25em&quot;, &quot;top&quot;:&quot;12px&quot;, &quot;height&quot;:&quot;100%&quot;, &quot;padding&quot;:&quot;0px&quot;, &quot;content&quot;:&quot;&quot;}"></div>
                      <div :class="{'company': true, 'active': requestingNode == name3, }">
                        <div class="company-name">
                          <prozess-resource-name
                            :id="`company-name-${name3}-link`" :title="company3.name"
                            :class="{'text-nowrap d-inline-block': true, 'requesting-node-name': requestingNode == name3, }"
                            :has-permission="$can('Read', 'CRM')"
                            :on-click="close"
                            :to="{ name: `${company3.schema}-view`, params: { id: name3, tab: GenericTabs.OVERVIEW_360, }, }"
                          />
                          <b-button :id="`popover-target-3-${company3.uuid}`" variant="link">
                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="18" height="18" viewBox="0 0 24 24" style="fill:#40C057; margin-top: -3px;"> <path d="M12,2C6.477,2,2,6.477,2,12s4.477,10,10,10s10-4.477,10-10S17.523,2,12,2z M12,17L12,17c-0.552,0-1-0.448-1-1v-4 c0-0.552,0.448-1,1-1h0c0.552,0,1,0.448,1,1v4C13,16.552,12.552,17,12,17z M12.5,9h-1C11.224,9,11,8.776,11,8.5v-1 C11,7.224,11.224,7,11.5,7h1C12.776,7,13,7.224,13,7.5v1C13,8.776,12.776,9,12.5,9z"></path></svg>
                          </b-button>
                          <b-popover :target="`popover-target-3-${company3.uuid}`" triggers="click" placement="top">
                            <template #title>{{ company3.name }}</template>
                            <b>{{ company3.relationName }}</b> til {{ company3.parentName }}
                            <div v-for="(infoTxt, ii) in company3.info" :key="ii">
                              <p style="margin:0px">
                                <span style="font-weight: 500;">{{ infoTxt.name + ": " }}</span>
                                <span>{{ infoTxt.value }}</span>
                              </p>
                            </div>
                          </b-popover>
                        </div>
                        <div class="sub-info">
                          <div v-for="(infoTxt, ii) in company3.info" :key="ii">
                            <p v-if="ii<3">
                              <span style="font-weight: 500;">{{ infoTxt.name + ": " }}</span>
                              <span>{{ infoTxt.value }}</span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <ul v-if="Object.values(company3.child).length>0" class="child">
                        <li v-for="(company4, name4, index4) in company3.child" :key="name4" class="item">
                          <div :class="{'company': true, 'active': requestingNode == name4, }">
                            <div class="company-name">
                              <prozess-resource-name
                                :id="`company-name-${name4}-link`" :title="company4.name"
                                :class="{'text-nowrap d-inline-block': true, 'requesting-node-name': requestingNode == name4, }"
                                :has-permission="$can('Read', 'CRM')"
                                :on-click="close"
                                :to="{ name: `${company4.schema}-view`, params: { id: name4, tab: GenericTabs.OVERVIEW_360, }, }"
                              />
                              <b-button :id="`popover-target-4-${index4}-${company4.uuid}`" variant="link">
                                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="18" height="18" viewBox="0 0 24 24" style="fill:#40C057; margin-top: -3px;"> <path d="M12,2C6.477,2,2,6.477,2,12s4.477,10,10,10s10-4.477,10-10S17.523,2,12,2z M12,17L12,17c-0.552,0-1-0.448-1-1v-4 c0-0.552,0.448-1,1-1h0c0.552,0,1,0.448,1,1v4C13,16.552,12.552,17,12,17z M12.5,9h-1C11.224,9,11,8.776,11,8.5v-1 C11,7.224,11.224,7,11.5,7h1C12.776,7,13,7.224,13,7.5v1C13,8.776,12.776,9,12.5,9z"></path></svg>
                              </b-button>
                              <b-popover :target="`popover-target-4-${index4}-${company4.uuid}`" triggers="click" placement="top">
                                <template #title>{{ company4.name }}</template>
                                <b>{{ company4.relationName }}</b> til {{ company4.parentName }}
                                <div v-for="(infoTxt, ii) in company4.info" :key="ii">
                                  <p style="margin:0px">
                                    <span style="font-weight: 500;">{{ infoTxt.name + ": " }}</span>
                                    <span>{{ infoTxt.value }}</span>
                                  </p>
                                </div>
                              </b-popover>
                            </div>
                            <div class="sub-info">
                              <div v-for="(infoTxt, ii) in company4.info" :key="ii">
                                <p v-if="ii<3">
                                  <span style="font-weight: 500;">{{ infoTxt.name + ": " }}</span>
                                  <span>{{ infoTxt.value }}</span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { GenericTabs } from '@/constants/app'
import crmRelationshipMappingService from '@/services/crmRelationshipMapping'

export default {
  name: 'CRMRelationshipMappingTree',
  props: {
    requestingNode: {
      type: String,
      required: true,
    },
    crmType: {
      type: String,
      required: true,
    },
    visible: {
      type: Object,
      required: true,
    },
    statusFilter: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      GenericTabs,
      loading: false,
      tree: null,
    }
  },
  watch: {
    '$route.query': {
      async handler(query) {
         if (this.$route.params.tab === 'companies') {
          await this.getTreeByEntity(query)
         }
      },
      immediate: true,
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async getTreeByEntity(query) {
      this.loading = true
      const { response } = await this.$async(crmRelationshipMappingService.getTreeByEntity(this.crmType, this.requestingNode, query))
      this.tree = response.data
      this.loading = false
    },
  },
}
</script>

<style>
.crm-relationship-mapping-tree {
    margin: 0px 18px 18px 18px;
    padding: 12px;
}
.corporate-structure * {
    margin: 0;
    padding: 0;
    vertical-align: baseline;
    outline: none;
}
.corporate-structure {
    position: relative;
}
.sub-info{
  margin-left: 16px;
}

.corporate-structure .company {
    line-height: 1.3em;
    font-size: 1em;
    display: inline-block;
    margin-left: 2em;
    padding: 0 0 0 0.3em;
    position: relative;
    height: 90px;
}
.corporate-structure .company:before {
    content: '';
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: transparent;
    border: 3px solid #CCC;
    position: absolute;
    background-color: white;
    z-index: 10;
    top: 0;
    left: -1.7em;
}
.corporate-structure .company.active:before {
    background-color: #249859;
    border-color: rgb(174, 213, 174);
}
.corporate-structure .company-name,.company-name-root {
    font-size: 1.2em;
    margin-bottom: 0.25em;
    display: flex;
    gap: 1rem;
}
.tooltip-company {
    position: relative;
    display: inline-block;
}
.corporate-structure .company .company-data {
    color: #fff;
}
.corporate-structure .child {
    margin-left: 2em;
    position: relative;
}
.corporate-structure .child .item:before {
    border-left: 3px dotted #CCCCCC;
    display: block;
    width: 0;
    position: absolute;
    left: -1.25em;
    top: -90px;
    height: 90px;
    padding: 0px;
    content: "";
}

.corporate-structure .company .company-name:before {
    width: 3.5em;
    border-top: 3px dotted #CCCCCC;
    display: block;
    content: "";
    height: 0;
    position: absolute;
    left: -63px;
    padding-right: 1em;
    top: 5px;
}
.panel ul li:first-child {
    margin-top: 0;
}
.requesting-node-name {
  border: solid 1px rgb(174, 213, 174);
  padding: 4px 10px;
  border-radius: 8px;
  background-color: rgb(244, 252, 244);
  color: black;
}
.corporate-structure .item-last:before {
    background-color: #FFFFFF;
    height: auto;
    bottom: 0;
}
.corporate-structure .item {
    position: relative;
    padding-left: 2em;
}
.panel ul li {
    margin-top: 6px;
}
.item::marker{
  content: "";
}
</style>
