<template>
  <b-card
    no-body
    class="system-settings-card prozess-custom-entity-data"
  >
    <div
      v-if="!customEntity"
      class="d-flex justify-content-center align-items-center"
      style="height: calc(100vh - 231px)"
    >
      <b-spinner class="align-middle" />
    </div>
    <div
      v-else
      class="m-2 custom-entity-table"
    >
      <sidebar-form
        v-if="!isOwningEntityAView"
        :visible="showForm"
        :resource-id="resourceId"
        :owning-schema="owningSchema"
        :owning-entity-type="owningEntityType"
        :owning-entity-id="owningEntityId"
        :related-entity-type="relatedEntityType"
        :table-columns="customEntity.columns"
        :relationships="customEntity.relationships"
        :service="dataService"
        :form-id="`${relatedSchema}-${relatedEntityType}-form`"
        @saved="getInitialData()"
        @close="handleFormClosure"
      />
      <link-existing-entity-form
        v-if="!isOwningEntityAView"
        :visible="showEntityLinker"
        :owning-schema="owningSchema"
        :owning-entity-type="owningEntityType"
        :owning-entity-id="owningEntityId"
        :related-schema="relatedSchema"
        :related-entity-type="relatedEntityType"
        :related-entity-name="customEntity.label"
        :related-entity-info="customEntity"
        :form-id="`link-${relatedSchema}-${relatedEntityType}-form`"
        @close="toggleLinkModal(false)"
        @saved="getInitialData()"
      />
      <div class="mb-2 d-flex table-actions">
        <template v-if="relatedSchema === Schema.CUSTOM">
          <b-button
            :id="`create-${relatedSchema}-${relatedEntityType}-button`"
            variant="primary"
            class="mr-1"
            :class="{ 'icon-disabled': !canUpdate }"
            @click="canUpdate && create()"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-50"
            />
            <span class="align-middle">{{ $t('Create New') }}</span>
          </b-button>
          <b-tooltip :target="`create-${relatedSchema}-${relatedEntityType}-button`">
            {{ canUpdate ? $t('Create') : $t('You dont have permission to create') }}
          </b-tooltip>
        </template>
        <b-button
          :id="`connect-${relatedSchema}-${relatedEntityType}-button`"
          variant="primary"
          :class="{ 'icon-disabled': !canUpdate }"
          @click="canUpdate && toggleLinkModal()"
        >
          <feather-icon
            icon="LinkIcon"
            class="mr-50"
          />
          <span class="align-middle">
            {{ `${$t('Search and Link')} ${customEntity.label}` }}
          </span>
        </b-button>
        <b-tooltip :target="`connect-${relatedSchema}-${relatedEntityType}-button`">
          {{ canUpdate ? `${$t('Search and Link')} ${customEntity.label}` : `${$t("You don't have permission to search and link")} ${customEntity.label}` }}
        </b-tooltip>
      </div>
      <b-table
        ref="refTable"
        class="table-responsive"
        responsive
        primary-key="id"
        show-empty
        :busy="loading"
        :items="items"
        :fields="customEntity.displayableOnly"
        :empty-text="$t('No matching records found')"
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        @sort-changed="handleSortChange"
      >
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle" />
          </div>
        </template>

        <template #head()="{ label, field }">
          <div
            :style="{
              'min-width': field.type === CustomFieldTypes.LongText ? '350px' : '',
            }"
          >
            <span style="white-space: nowrap">{{ label }}</span>
          </div>
        </template>

        <template #cell()="{ item, field }">
          <prozess-resource-name
            v-if="field.partOfName"
            :has-permission="$can('Read', getPermissionName(relatedEntityType))"
            :to="
              formatRouterLink({
                schemaName: relatedSchema,
                tableKey: relatedEntityType,
                uuidKey: item.uuid_key,
              })
            "
          >
            <template slot="custom-title">
              <item
                :value="item[field.key]"
                :type="field.type"
              />
            </template>
          </prozess-resource-name>

          <div
            v-else-if="field.key === INDICATOR_ACTIONS"
            class="text-nowrap"
          >
            <template v-if="relatedSchema === Schema.CUSTOM">
              <template v-if="customEntity.type === CustomEntityType.VIEW">
                <feather-icon
                  :id="`custom-entity__${item.uuid_key}-goto-main-entity-icon`"
                  icon="CornerLeftUpIcon"
                  size="16"
                  class="mx-1 cursor-pointer"
                  :class="{
                    'icon-disabled': !canGoToMainEntity,
                  }"
                  @click="canGoToMainEntity ? goToMainEntity(item) : null"
                />
                <b-tooltip
                  :title="canGoToMainEntity ? $t('Go to the main data source') : `${$t('no-view-permission-text')} ${mainSourceTable}`"
                  :target="`custom-entity__${item.uuid_key}-goto-main-entity-icon`"
                  placement="left"
                />
              </template>
              <feather-icon
                :id="`edit-row-${item.uuid_key}-preview-icon`"
                icon="EditIcon"
                size="16"
                class="mx-1 cursor-pointer"
                :class="{
                  'icon-disabled': !canUpdate,
                }"
                @click="canUpdate ? edit(item.uuid_key) : null"
              />
              <b-tooltip
                :title="canUpdate ? $t('Edit') : $t('You dont have permission to update')"
                :target="`edit-row-${item.uuid_key}-preview-icon`"
                placement="left"
              />
            </template>
            <feather-icon
              :id="`delete-row-${item.uuid_key}-preview-icon`"
              icon="TrashIcon"
              size="16"
              class="mx-1 cursor-pointer"
              :class="{
                'icon-disabled': !canUpdate,
              }"
              @click="confirmRemoval(item)"
            />
            <b-tooltip
              :title="canUpdate ? $t('Delete') : $t('You dont have permission to remove')"
              :target="`delete-row-${item.uuid_key}-preview-icon`"
              placement="left"
            />
          </div>
          <span
            v-else
            style="white-space: nowrap"
          >
            <prozess-resource-name
              v-if="item[`${field.key}-item-uuidKey`]"
              :has-permission="$can('Read', getPermissionName(item[`${field.key}-item-table`]))"
              :to="
                formatRouterLink({
                  schemaName: item[`${field.key}-item-schema`],
                  tableKey: item[`${field.key}-item-table`],
                  uuidKey: item[`${field.key}-item-uuidKey`],
                })
              "
            >
              <template slot="custom-title">
                <item
                  :value="item[field.key]"
                  :type="field.type"
                />
              </template>
            </prozess-resource-name>
            <item
              v-else
              :value="item[field.key]"
              :relationship-id="item.relationshipId"
              :type="field.type"
            />
          </span>
        </template>
      </b-table>
    </div>
    <prozess-pagination
      v-if="!loading"
      class="mx-2 mb-2 mt-2"
      :class="{ 'd-none': !total }"
      :meta="dataMeta"
      :page="currentPage"
      :total="total"
      :size="perPage"
      @change="handlePageChange"
    />
  </b-card>
</template>

<script>
import { columnsWithFavorites, formatColumnsForTable, formatRouterLink, getPermissionName, mapRowData } from '@/helpers/customEntity'
import { INDICATOR_ACTIONS, Schema } from '@/constants/app'
import { CustomEntityType } from '@/constants/customEntity'
import dataService from '@/services/data'
import infoService from '@/services/info'
import CustomFieldTypes from '@/constants/customFieldTypes'
import Item from '@/views/pages/CustomEntity/List/Item.vue'
import detachOrDeleteMixinFactory from '@/mixins/detachOrDeleteMixin'
import SidebarForm from '@/views/pages/CustomEntity/Form/index.vue'
import LinkExistingEntityForm from '@/views/pages/CustomEntity/Form/LinkExistingEntityForm.vue'
import goToMainEntityMixin from '@/mixins/goToMainEntity'
import advancedFieldMgmtService from '@/services/advancedFieldMgmt'

export default {
  name: 'XToManyList',
  components: {
    Item,
    SidebarForm,
    LinkExistingEntityForm,
  },

  mixins: [
    detachOrDeleteMixinFactory({
      detachOnlyNotice: null,
    }),
    goToMainEntityMixin,
  ],

  props: {
    owningSchema: {
      type: String,
      required: true,
    },

    owningEntityType: {
      type: String,
      required: true,
    },

    owningEntityId: {
      type: [Number, String],
      required: true,
    },

    relatedSchema: {
      type: String,
      required: true,
    },

    relatedEntityType: {
      type: String,
      required: true,
    },

    relatedEntityId: {
      type: [String, Number],
      default: null,
    },

    isOwningEntityAView: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      INDICATOR_ACTIONS,
      CustomEntityType,
      Schema,
      CustomFieldTypes,
      loading: false,
      items: [],
      currentPage: 1,
      total: 10,
      perPage: 25,
      customEntity: null,
      resourceId: null,
      showForm: false,
      showEntityLinker: false,
      dataService,
      sortBy: this.$route.query.sortBy || '',
      sortDesc: this.$route.query.sortDesc === 'true',
      sort: {},
    }
  },

  computed: {
    canUpdate() {
      const hasPermission = this.owningSchema === Schema.CRM ? this.$can('Update', 'CRM') : this.$can('Update', this.owningEntityType)

      return hasPermission && !this.isOwningEntityAView && this.customEntity.type !== CustomEntityType.VIEW
    },

    canDetachOrDelete() {
      return this.canUpdate
    },

    dataMeta() {
      const localItemsCount = this.items.length

      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.total,
      }
    },
  },

  watch: {
    '$route.params': {
      handler(params) {
        if (params.tab === this.relatedEntityType) {
          this.getInitialData()
        }
      },

      immediate: true,
    },
  },

  methods: {
    getPermissionName,

    async checkIfDetachOnly() {
      return true
    },
    async getEntityOrder() {
      const { response } = await this.$async(advancedFieldMgmtService.getorder(
        { schema: this.relatedSchema,
          table: this.relatedEntityType,
        }))

      const keyOrder = response.data
      if (keyOrder.length > 0) {
        let sorteDisplayColumn = JSON.parse(JSON.stringify(this.customEntity.displayableOnly))
        const firstCol = sorteDisplayColumn.shift()
        const lastCol = sorteDisplayColumn.pop()
        if (firstCol === 'uuid_key') {
          sorteDisplayColumn.unshift(firstCol)
        }
        if (lastCol === '__actions') {
          sorteDisplayColumn.push(lastCol)
        }
        const unsortedJson = {}
        sorteDisplayColumn.forEach(field => { unsortedJson[field.key] = field })
        const sortedList = []
        keyOrder.forEach(fieldKey => {
          if (unsortedJson[fieldKey] != null) {
            sortedList.push(unsortedJson[fieldKey])
          }
        })
        sorteDisplayColumn = JSON.parse(JSON.stringify(sortedList))

        if (firstCol != null) {
          sorteDisplayColumn.unshift(firstCol)
        }
        if (lastCol != null) {
          sorteDisplayColumn.push(lastCol)
        }
        this.customEntity.displayableOnly = sorteDisplayColumn
      }
    },

    create() {
      this.resourceId = null
      this.showForm = true
    },

    edit(id) {
      if (!this.canUpdate) {
        return
      }

      this.resourceId = id
      this.showForm = true
    },

    formatRouterLink,

    async getAllRelatedEntityData() {
      const { page, sortDesc, sortBy } = this.$route.query
      this.currentPage = page || 1

      const query = {
        page: page ? page - 1 : 0,
        size: 25,
      }

      this.sortBy = sortBy
      if (this.sortBy) {
        this.sortDesc = sortDesc === 'true' || sortDesc === true
        const order = this.sortDesc ? 'desc' : 'asc'
        query.sortBy = `${order}.${this.sort[this.sortBy] || this.sortBy}`
      }

      if (this.relatedEntityId) {
        query.relationshipId = this.relatedEntityId
      }

      const { error, response } = await this.$async(
        dataService.getAllRelatedEntityData(
          {
            owningSchema: this.owningSchema,
            owningEntityType: this.owningEntityType,
            owningEntityId: this.owningEntityId,
            relatedSchema: this.relatedSchema,
            relatedEntityType: this.relatedEntityType,
          },
          query,
        ),
      )

      if (!error) {
        this.total = response.data.filteredItemsCount
        this.items = mapRowData(response.data.pageItems)
      }
    },

    async getInitialData() {
      this.loading = true

      await this.getRelatedEntityInfo()
      await this.getAllRelatedEntityData()
      await this.getEntityOrder()
      this.loading = false
    },

    async getRelatedEntityInfo() {
      const { response } = await this.$async(
        infoService.getRelatedEntityInfo(this.owningSchema, this.owningEntityType, this.relatedSchema, this.relatedEntityType),
      )

      this.customEntity = response.data

      const { value, displayableOnly } = formatColumnsForTable(this.customEntity.columns, {
        displayableOnly: true,
        sortable: true,
      })

      this.sort = displayableOnly.reduce((_sort, item) => {
        _sort[item.key] = item.key
        return _sort
      }, {})
      this.customEntity.columns = value
      this.customEntity.displayableOnly = columnsWithFavorites(this.customEntity, displayableOnly)
    },

    handlePageChange(page) {
      if (!page || page === parseInt(this.$route.query.page)) {
        return
      }

      this.updateRouteQuery({ ...this.$route.query, page })
    },

    handleFormClosure() {
      this.showForm = false
      this.$tick(() => {
        this.resourceId = null
      })
    },

    handleSortChange({ sortBy, sortDesc }) {
      this.updateRouteQuery({ ...this.$route.query, sortBy, sortDesc })
    },

    async remove(action, item) {
      await this.$async(
        dataService.detachOrDelete(
          action,
          {
            owningSchema: this.owningSchema,
            owningEntityType: this.owningEntityType,
            owningEntityId: this.owningEntityId,
            relatedSchema: this.relatedSchema,
            relatedEntityType: this.relatedEntityType,
            relatedEntityId: item.uuid_key,
          },
          { version: item.version },
        ),
      )

      this.getInitialData()
    },

    toggleLinkModal(show = true) {
      this.showEntityLinker = show
    },

    updateRouteQuery(query) {
      this.$router.push({
        name: this.$route.name,
        params: { tab: this.$route.params.tab },
        query,
      })
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/custom-entity-data.scss';
</style>
