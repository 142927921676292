<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="auto"
        class="d-flex"
      >
        <b-button
          id="create-button"
          variant="primary"
          class="mb-1"
          :class="{
            'icon-disabled': !hasSystemSettingsActions,
          }"
          @click="openModal()"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
          />
          <span class="align-middle">{{ $t('Create New') }}</span>
        </b-button>
        <b-tooltip target="create-button">
          {{ hasSystemSettingsActions ? $t('Create') : $t('no-action-permission') }}
        </b-tooltip>
      </b-col>
    </b-row>
    <b-table
      ref="refTable"
      :busy="loading"
      :items="items"
      responsive
      :fields="tableColumns"
      primary-key="id"
      show-empty
      :empty-text="$t('No matching records found')"
      class="table-responsive"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      @sort-changed="handleSortChange"
    >
      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle" />
        </div>
      </template>
      <template #head()="{ label }">
        <div class="w-100 text-center">
          <span class="text-nowrap">{{ $t(label) }}</span>
        </div>
      </template>
      <template #cell(companyName)="{ item }">
        <div style="max-width: 240px">
          <span>{{ item.companyName?.length > 60 ? item.companyName.substring(0, 60) + '...' : item.companyName }}</span>
        </div>
      </template>
      <template #cell(period)="{ item }">
        <div class="w-100 text-center">
          <span class="text-nowrap">{{ dayjs(item.startAt).format('DD-MM-YYYY') + ' - ' + dayjs(item.endAt).format('DD-MM-YYYY') }}</span>
        </div>
      </template>
      <template #cell(modelName)="{ item }">
        <div style="max-width: 240px">
          <span>{{ item.modelName?.length > 60 ? item.modelName.substring(0, 60) + '...' : item.modelName }}</span>
        </div>
      </template>
      <template #cell(modelType)="{ item }">
        <div class="w-100 text-center">
          <span class="text-nowrap"> {{ item.modelType === 'PERCENTAGE' ? 'Percentage (%) Based' : 'Amount Based' }}</span>
        </div>
      </template>
      <template #cell(modelAgreementType)="{ item }">
        <div class="w-100 text-center">
          <span class="text-nowrap">{{ item.modelAgreementType }}</span>
        </div>
      </template>

      <!-- START: Actions Column -->
      <template #cell(manage)="{ item }">
        <div class="d-flex align-items-center justify-content-center">
          <!-- Clone Button -->
          <feather-icon
            :id="`tooltip-${item.uuid}-clone`"
            class="mx-1 cursor-pointer"
            icon="CopyIcon"
            size="16"
            @click="openModal(item, true)"
          />
          <b-tooltip
            :target="`tooltip-${item.uuid}-clone`"
            :title="$t('Clone')"
            placement="top"
          />
          <!-- Edit Button -->
          <feather-icon
            :id="`tooltip-${item.uuid}-edit`"
            :style="{ opacity: dayjs(new Date()).isBetween(item.startAt, item.endAt) ? 0.4 : 1 }"
            class="mx-1 cursor-pointer"
            icon="EditIcon"
            size="16"
            @click="openModal(item,false)"
          />
          <b-tooltip
            v-if="dayjs(new Date()).isBetween(item.startAt, item.endAt)"
            :target="`tooltip-${item.uuid}-edit`"
            :title="$t('Cannot be edited')"
            placement="top"
          />
          <b-tooltip
            v-else
            :target="`tooltip-${item.uuid}-edit`"
            :title="$t('Edit')"
            placement="top"
          />
          <!-- Delete Button -->
          <feather-icon
            :id="`tooltip-${item.uuid}-delete`"
            size="16"
            icon="TrashIcon"
            :style="{ opacity: dayjs(new Date()).isBetween(item.startAt, item.endAt) ? 0.4 : 1 }"
            class="mx-1 cursor-pointer"
            @click="remove(item)"
          />
          <b-tooltip
            v-if="dayjs(new Date()).isBetween(item.startAt, item.endAt)"
            :target="`tooltip-${item.uuid}-delete`"
            :title="$t('Cannot be deleted')"
            placement="top"
          />
          <b-tooltip
            v-else
            :target="`tooltip-${item.uuid}-delete`"
            :title="$t('Delete')"
            placement="top"
          />
        </div>
      </template>
      <!-- END: Action Column -->
    </b-table>
    <!-- END: Table -->

    <!-- START: Pagination -->
    <prozess-pagination
      v-if="!loading"
      class="mx-2 mb-2"
      :class="{ 'd-none': !total }"
      :meta="dataMeta"
      :page="currentPage"
      :total="total"
      :size="perPage"
      @change="handleCurrentPage"
    />
    <!-- END: Pagination -->
    <AddCommissionModal
      ref="addCommissionModal"
      @submit="save"
    />
  </b-card>
</template>

<script>
import { Schema, Entity } from '@/constants/app'
import commissionsService from '@/services/commissions'
import ImexActionsDropdown from '@/views/shared/Imex/ImexActionsDropdown.vue'
import { BButton, BForm, BFormInput, BFormGroup, BCardText } from 'bootstrap-vue'
import dayjs from 'dayjs'
import ImexAvailableExportsDropdown from '@/views/shared/Imex/ImexAvailableExportsDropdown.vue'
import isBetween from 'dayjs/plugin/isBetween'
import listMixinFactory from '@/mixins/list'
import DatePicker from 'vue2-datepicker'
import DateRangeFilter from '@/components/Lists/DateRangeFilter.vue'
import AddCommissionModal from './AddCommissionModal.vue'

// Extend dayjs with the isBetween plugin
dayjs.extend(isBetween)

export default {
  name: 'Commissions',
  components: {
    ImexActionsDropdown,
    ImexAvailableExportsDropdown,
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCardText,
    DatePicker,
    DateRangeFilter,
    AddCommissionModal,
  },
  mixins: [
    listMixinFactory({
      autoRouteName: true,
      isCustomizable: false,
      listFormat: true,
      searchField: 'companyName',
      service: commissionsService,
      sort: {
        name: 'companyName',
      },
      disableAutoFetchlist: true,
      tableColumns: [
        { key: 'companyName', label: 'Company Name', sortable: true },
        { key: 'ourContact', label: 'Vår Kontakt', sortable: true },
        { key: 'period', label: 'Period', sortable: true },
        { key: 'modelName', label: 'Model Name', sortable: true },
        { key: 'modelType', label: 'Model Type', sortable: true },
        { key: 'modelAgreementType', label: 'Agreement Type', sortable: true },
        { key: 'manage', label: 'Manage' },
      ],
    }),
  ],
  data() {
    return {
      dayjs,
      Schema,
      Entity,
      loading: false,
      onExport: false,
      // create array from 2023 to current year
      years: Array.from({ length: new Date().getFullYear() - 2021 }, (_, i) => new Date().getFullYear() + 1 - i).map(year => ({
        name: year,
      })),
      items: [],
      yearFilter: null,
    }
  },

  watch: {
    '$route.params': {
      immediate: true,
      handler(params) {
        if (params?.tab === 'commissions') {
          this.fetchList()
        }
      },
    },
  },

  methods: {
    showNotice(io) {
      this.$swal({
        title: this.$t(`imex-action-${io}-notice`),
        icon: 'info',
        confirmButtonText: this.$t('Close'),
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
        allowOutsideClick: false,
      })
    },

    async get() {
      this.loading = true
      const q = {
        page: 0,
        size: 10,
        companyId: this.$route?.params?.idFeed,
      }
      const res = await commissionsService.getAll(q)
      if (!res.data) return
      this.items = res.data.pageItems
      this.loading = false
    },
    strToDate(str1) {
      if (str1 != null) {
        const m = parseInt(str1.substring(5, 8))
        const d = parseInt(str1.substring(8, 10))
        const y = parseInt(str1.substring(0, 5))
        return new Date(y, m - 1, d)
      }
      return null
    },
    getDateRangeExporTooltiptMsg() {
      let msg = 'Export'
      if (this.periodType == null) {
        msg = 'Select the year or date range to export'
      } else if (this.periodType === 'custom') {
        if (this.$route.query.startDate == null && this.$route.query.endDate == null) {
          msg = 'Select date range'
        } else if (this.strToDate(this.$route.query.startDate).getFullYear() !== this.strToDate(this.$route.query.endDate).getFullYear()) {
          msg = 'Date range should have the same year'
        }
      }
      return msg
    },
    async initiateExport() {
      if (this.getDateRangeExporTooltiptMsg() === 'Export') {
        this.onExport = true
        if (this.$route.query.year != null) {
          await this.$async(commissionsService.exportCommissionByYear(this.$route.query))
        } else if (this.$route.query.startDate && this.$route.query.endDate) {
          await this.$async(commissionsService.exportCommissionByPeriod(this.$route.query))
        }
        this.onExport = false
      }
    },
    isCustomDateFilter() {
      return this.periodType === 'custom'
    },
    setCustomYearFilter(year) {
        this.yearFilter = year
        this.periodType = 'year'
    },
    setCustomDateFilter() {
      if (this.periodType !== 'custom') {
        this.$router.push({ query: { page: 1 } })
        this.periodType = 'custom'
        this.yearFilter = null
      }
    },
    disableExport() {
      return !this.hasSystemSettingsActions || this.onExport || this.getDateRangeExporTooltiptMsg() !== 'Export'
    },
    save(c) {
      this.get()
    },
    select(m) {
      this.$router.push({ name: 'commission-models', params: { id: m.uuidKey } })
    },
    remove(item) {
      if (this.$can('Delete', 'CRM') === false || this.dayjs(new Date()).isBetween(item.startAt, item.endAt)) return

      this.$swal({
        title: this.$t('Warning'),
        text: this.$t('Are you sure you want to remove?'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('No'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          commissionsService.deleteCommission(item.uuid).then(() => {
            this.get()
          })
        }
      })
    },
    async openModal(item, isClone) {
      console.log('edit', item)
      if (this.$can('Create', 'CRM') === false || (item && this.dayjs(new Date()).isBetween(item.startAt, item.endAt))) return

      await this.$refs.addCommissionModal.openModal({
        open: true,
        item,
        isClone,
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
</style>
